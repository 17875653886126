
import brainstar1 from '../../assets/img/product/brainstar1.png'
import brainstar2 from '../../assets/img/product/brainstar2.png'

/**
 * 脑力星
 * @returns 
 */
const ProductBrainstar = () => {

  return <div className="w-1200 m-auto my-6 p-8 bg-white page_productdetail">
    <div className="w-full flex flex-row">
      <div>
        <img style={{ width: '490px' }} src={brainstar1} alt="" />
      </div>
      <div className='pt-8 pl-8' style={{ width: '645px' }}>
        <div className='text-2xl font-bold'>脑力星</div>
        <div className='text-base mt-4'>基本介绍</div>
        <div className='text-sm mt-4 leading-6'>“脑力星”以无接触的方式实现反馈训练，并结合脑机技术采集脑电数据，可以实现对反应力、注意力、空间能力、记忆力、情绪能力、思维力、动作反应、听觉反应等多项认知能力评估、训练，同步生成训练报告。尤其对发育迟缓的学生，能辅助增强其信息加工能力、大小动作迟缓和记忆迟缓。</div>
      </div>
    </div>
    <div className='w-full mt-8'>
      <div className='text-base font-bold'>脑力星的优势：</div>
      <div className='text-sm mt-4 leading-6'>1.脑力星结合人的自然肢体运动，以无接触感知的方式实时采集多项参数，真实 的反馈了大脑脑神经网络的执行效能。</div>
      <div className='text-sm mt-4 leading-6'>2.听觉反应和视觉反应的评估及训练，自感知、自动统计结果，避免了人为主观因素造成的误判。</div>
      <div className='text-sm mt-4 leading-6'>3.趣味性强：测评和训练基于心理学实验范式，操作便捷，人机互动、脑机反馈，软硬件同步呈现刺激信号，趣味性极强。 </div>
      <div className='text-sm mt-4 leading-6'>4.感应器可根据应用场景灵活布置，可柔性地适应各种训练场景，比如学校心理活动室，培训机构训练教室、户外团体心理辅导活动等。</div>
      <div className='text-sm mt-4 leading-6'>一套“脑力星团体活动包”包括多个脑力星感应器，2套Ego X便携式脑电仪，以及配套的脑力星APP软件。注:用户须自配平板或手机。</div>
      <img className='w-2/3 m-auto mt-4' src={brainstar2} alt='' />
    </div>
  </div>
}

export default ProductBrainstar