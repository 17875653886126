

import { useNavigate } from 'react-router-dom'
import banner1 from '../../assets/img/banner1.png'
import SysProduct from '../../components/SysProduct'
import img from '../../assets/img/product.png'
import mindlight1 from '../../assets/img/product/mindlight_1.png'
import cuband1 from '../../assets/img/product/cuband_1.png'
import thinkband1 from '../../assets/img/product/thinkband_1.png'
import egox from '../../assets/img/product/egox.png'
import brainstar1 from '../../assets/img/product/brainstar1.png'
import penguin1 from '../../assets/img/product/penguin1.png'

const productListData = [
  {
    id: '1',
    name: '思语脑波灯',
    des: '单通道便携式脑电仪，采用非侵入式干电极采',
    imgUrl: mindlight1,
    path: 'mindlight'
  },
  {
    id: '2',
    name: 'Cuband便携式脑电仪',
    des: '单通道便携式脑电仪，采用非侵入式干电极采',
    imgUrl: cuband1,
    path: 'cuband'
  },
  {
    id: '3',
    name: 'Thinkband便携式脑电仪',
    des: '单通道便携式脑电仪，采用非侵入式干电极采',
    imgUrl: thinkband1,
    path: 'thinkband'
  },
  {
    id: '4',
    name: 'Egox便携式脑电仪',
    des: '单通道便携式脑电仪，采用非侵入式干电极采',
    imgUrl: egox,
    path: 'egox'
  },
  {
    id: '5',
    name: '脑力星',
    des: '单通道便携式脑电仪，采用非侵入式干电极采',
    imgUrl: brainstar1,
    path: 'brainstar'
  },
  {
    id: '6',
    name: '脑云梯-脑智综合评估系统',
    des: '单通道便携式脑电仪，采用非侵入式干电极采',
    imgUrl: penguin1,
    path: 'penguin'
  },
  {
    id: '7',
    name: 'B-Mindata脑认知大数据',
    des: '单通道便携式脑电仪，采用非侵入式干电极采',
    imgUrl: img,
    path: 'bmindata'
  },
  {
    id: '8',
    name: '脑机互动体验产品',
    des: '单通道便携式脑电仪，采用非侵入式干电极采',
    imgUrl: img,
    path: 'others'
  },
]

/**
 * 产品列表
 * @returns 
 */
const ProductList = () => {

  const navigate = useNavigate()

  const onClickProduct = (data) => {
    navigate('/' + data.path)
  }

  return <div className="w-full max-w-1920 min-w-1200 h-full mx-auto bg-white pb-6 page_productlist">
    <div className='w-full pt-16'>
      <div className='w-full bg-cover' style={{ height: '220px', backgroundImage: `url(${banner1})`, backgroundPositionX: 'center' }}></div>
    </div>

    <div className='w-full m-auto mt-16 rounded-3xl flex flex-wrap' style={{ width: '1224px' }}>
      {productListData.map((item) => { return <SysProduct key={'product_' + item.id} imgUrl={item.imgUrl} name={item.name} des={item.des} data={item} clickCallback={onClickProduct} /> })}
    </div>
  </div>
}

export default ProductList