import { useState } from 'react'
import { Carousel } from 'antd'
import './index.css'

import title from '../../assets/img/exhibit/exhibit_title.png'

import icon_authentication from '../../assets/img/exhibit/exhibit_authentication.png'
import icon_honor from '../../assets/img/exhibit/exhibit_honor.png'
import icon_laboratory from '../../assets/img/exhibit/exhibit_laboratory.png'
import icon_patent from '../../assets/img/exhibit/exhibit_patent.png'

import carousel1 from '../../assets/img/exhibit/carousel1.png'
import carousel2 from '../../assets/img/exhibit/carousel2.png'
import carousel3 from '../../assets/img/exhibit/carousel3.png'
import carousel4 from '../../assets/img/exhibit/carousel4.png'

import laboratory1 from '../../assets/img/exhibit/certificate/laboratory1.png'
import laboratory2 from '../../assets/img/exhibit/certificate/laboratory2.png'
import authentication1 from '../../assets/img/exhibit/certificate/authentication1.png'
import authentication2 from '../../assets/img/exhibit/certificate/authentication2.png'
import authentication3 from '../../assets/img/exhibit/certificate/authentication3.png'
import authentication4 from '../../assets/img/exhibit/certificate/authentication4.png'
import authentication5 from '../../assets/img/exhibit/certificate/authentication5.png'
import authentication6 from '../../assets/img/exhibit/certificate/authentication6.png'
import patent1 from '../../assets/img/exhibit/certificate/patent1.png'
import patent2 from '../../assets/img/exhibit/certificate/patent2.png'
import patent3 from '../../assets/img/exhibit/certificate/patent3.png'
import patent4 from '../../assets/img/exhibit/certificate/patent4.png'
import patent5 from '../../assets/img/exhibit/certificate/patent5.png'
import patent6 from '../../assets/img/exhibit/certificate/patent6.png'

/**
 * 展示栏
 */
const SysExhibit = () => {
  /**
 * 活动状态的tab
 */
  const [activeTab, setActiveTab] = useState('laboratory')
  const [carouselTab, setCarouselTab] = useState('laboratory')

  /**
   * 点击 标签
   */
  const onClickTabs = (activeClassName) => {
    setActiveTab(activeClassName)
    setCarouselTab(activeClassName)
  }

  return <div className='w-1200 pt-16 flex flex-col items-center mx-auto sysExhibit'>
    <div className='w-auto text-4xl font-bold mb-4'>荣誉与资质</div>
    <img style={{ width: '621px' }} src={title} alt='' />
    <div className='w-full flex flex-row justify-between mt-8' style={{ height: '400px' }}>
      {/* 左侧tab */}
      <div className='h-full relative' style={{ width: '200px' }}>
        <div className='w-px h-full absolute z-0 border border-dashed border-gray-400' style={{ left: '28px' }}></div>
        <div className='w-full h-full absolute z-10 left-0 top-0'>
          <div className='w-full h-full flex flex-col justify-between'>

            <div className={`flex flex-row items-center cursor-pointer ${activeTab === 'laboratory' ? 'active_laboratory' : ''}`} onClick={() => { onClickTabs('laboratory') }}>
              <div className='bg-gray-300 rounded-lg tab_bg' style={{ width: '58px', height: '58px', padding: '5px' }}>
                <img style={{ width: '48px' }} src={icon_laboratory} alt='' />
              </div>
              <div className='text-base ml-4 tab_text'>联合实验室</div>
            </div>

            <div className={`flex flex-row items-center cursor-pointer ${activeTab === 'authentication' ? 'active_authentication' : ''}`} onClick={() => { onClickTabs('authentication') }}>
              <div className='bg-gray-300 rounded-lg tab_bg' style={{ width: '58px', height: '58px', padding: '5px' }}>
                <img style={{ width: '48px' }} src={icon_authentication} alt='' />
              </div>
              <div className='text-base ml-4 tab_text'>资质认证</div>
            </div>

            <div className={`flex flex-row items-center cursor-pointer ${activeTab === 'patent' ? 'active_patent' : ''}`} onClick={() => { onClickTabs('patent') }}>
              <div className='bg-gray-300 rounded-lg tab_bg' style={{ width: '58px', height: '58px', padding: '5px' }}>
                <img style={{ width: '48px' }} src={icon_patent} alt='' />
              </div>
              <div className='text-base ml-4 tab_text'>国家专利</div>
            </div>

            <div className={`flex flex-row items-center cursor-pointer ${activeTab === 'honor' ? 'active_honor' : ''}`} onClick={() => { onClickTabs('honor') }}>
              <div className='bg-gray-300 rounded-lg tab_bg' style={{ width: '58px', height: '58px', padding: '5px' }}>
                <img style={{ width: '48px' }} src={icon_honor} alt='' />
              </div>
              <div className='text-base ml-4 tab_text'>所获荣誉</div>
            </div>

          </div>
        </div>
      </div>

      {/* 右侧展示 */}
      <div className='h-full' style={{ width: '940px', backgroundImage: `url(${carousel1})`, backgroundSize: '100% 100%', display: `${carouselTab === 'laboratory' ? 'block' : 'none'}` }} >
        <Carousel dots={false}>
          <div>
            <div className='flex flex-row justify-center items-center' style={{ height: '400px' }}>
              <div className='w-full flex flex-row justify-start px-9'>
                <img src={laboratory1} alt='' style={{ height: '185px' }} />
                <img src={laboratory2} alt='' style={{ height: '185px' }} />
              </div>
            </div>
          </div>
        </Carousel>
      </div>

      <div className='h-full' style={{ width: '940px', backgroundImage: `url(${carousel2})`, backgroundSize: '100% 100%', display: `${carouselTab === 'authentication' ? 'block' : 'none'}` }} >
        <Carousel dotPosition='bottom'>
          <div>
            <div className='flex flex-row justify-center items-center' style={{ height: '400px' }}>
              <div className='w-full flex flex-row justify-evenly px-9'>
                <img src={authentication1} alt='' style={{ height: '185px' }} />
                <img src={authentication2} alt='' style={{ height: '185px' }} />
                <img src={authentication3} alt='' style={{ height: '185px' }} />
              </div>
            </div>
          </div>
          <div>
            <div className='flex flex-row justify-center items-center' style={{ height: '400px' }}>
              <div className='w-full flex flex-row justify-evenly px-9'>
                <img src={authentication4} alt='' style={{ height: '185px' }} />
                <img src={authentication5} alt='' style={{ height: '185px' }} />
                <img src={authentication6} alt='' style={{ height: '185px' }} />
              </div>
            </div>
          </div>
        </Carousel>
      </div>

      <div className='h-full' style={{ width: '940px', backgroundImage: `url(${carousel3})`, backgroundSize: '100% 100%', display: `${carouselTab === 'patent' ? 'block' : 'none'}` }} >
        <Carousel dotPosition='bottom'>
          <div>
            <div className='flex flex-row justify-center items-center' style={{ height: '400px' }}>
              <div className='w-full flex flex-row justify-evenly px-9'>
                <img src={patent1} alt='' style={{ height: '185px' }} />
                <img src={patent2} alt='' style={{ height: '185px' }} />
                <img src={patent3} alt='' style={{ height: '185px' }} />
              </div>
            </div>
          </div>
          <div>
            <div className='flex flex-row justify-center items-center' style={{ height: '400px' }}>
              <div className='w-full flex flex-row justify-evenly px-9'>
                <img src={patent4} alt='' style={{ height: '185px' }} />
                <img src={patent5} alt='' style={{ height: '185px' }} />
                <img src={patent6} alt='' style={{ height: '185px' }} />
                <img src={patent6} alt='' style={{ height: '185px' }} />
              </div>
            </div>
          </div>
        </Carousel>
      </div>

      <div className='h-full' style={{ width: '940px', backgroundImage: `url(${carousel4})`, backgroundSize: '100% 100%', display: `${carouselTab === 'honor' ? 'block' : 'none'}` }} >
        <Carousel dotPosition='bottom'>
          <div>
            <div className='flex flex-row justify-center items-center' style={{ height: '400px' }}>
              <div className='w-full flex flex-row justify-evenly px-9'>
                <img src={patent1} alt='' style={{ height: '185px' }} />
                <img src={patent2} alt='' style={{ height: '185px' }} />
                <img src={patent3} alt='' style={{ height: '185px' }} />
              </div>
            </div>
          </div>
          <div>
            <div className='flex flex-row justify-center items-center' style={{ height: '400px' }}>
              <div className='w-full flex flex-row justify-evenly px-9'>
                <img src={patent4} alt='' style={{ height: '185px' }} />
                <img src={patent5} alt='' style={{ height: '185px' }} />
                <img src={patent6} alt='' style={{ height: '185px' }} />
                <img src={patent6} alt='' style={{ height: '185px' }} />
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  </div>
}

export default SysExhibit