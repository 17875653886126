import about from '../../assets/img/about.png'
import about2 from '../../assets/img/about2.png'

/**
 * 关于
 * @returns 
 */
const About = () => {
  return <div className="w-1200 h-full m-auto py-6">
    <div className='w-full bg-cover' style={{ height: '320px', backgroundImage: `url(${about})`, backgroundSize: '100% 100%' }}></div>

    <div className='w-full mt-6'>
      <div className='flex flex-row'>
        <div style={{ width: '14px', height: '40px', backgroundImage: `url(${about2})`, backgroundSize: '100% 100%' }}></div>
        <div className='font-bold text-3xl ml-4'>我们的使命</div>
      </div>
      <div className='leading-6 mt-4'>北京视友科技成立于2004年10月，是一家以“脑机接口”技术为核心，专业从事脑科学领域应用产品研发、生产及销售的国家高新技术企业，是脑机接口产业联盟的首批会员单位。公司核心团队成员来自清华大学、人民大学、南京大学、华东理工大学、航天员科研训练中心等高校和科研院所。</div>
    </div>

    <div className='w-full mt-6'>
      <div className='flex flex-row'>
        <div style={{ width: '14px', height: '40px', backgroundImage: `url(${about2})`, backgroundSize: '100% 100%' }}></div>
        <div className='font-bold text-3xl ml-4'>我们的团队</div>
      </div>
      <div className='leading-6 mt-4'>北京视友科技成立于2004年10月，是一家以“脑机接口”技术为核心，专业从事脑科学领域应用产品研发、生产及销售的国家高新技术企业，是脑机接口产业联盟的首批会员单位。公司核心团队成员来自清华大学、人民大学、南京大学、华东理工大学、航天员科研训练中心等高校和科研院所。</div>
      <div className='leading-6 mt-4'>作为中关村国家自主创新示范区脑机接口代表型企业，视友科技多年来立足自主研发，致力于为脑机接口技术在各领域的应用提供一体化的解决方案，现已推出了八个系列30余款产品，覆盖脑机交互、脑机智能、脑健康、心理健康、儿童青少年脑智评估及提升平台、脑电认知大数据、脑电AI算法、神经营销、脑电生物反馈、科教展览、娱乐等应用领域。截至2024年，公司已获得20余项国家专利和70余项软件著作权，公司产品也多次入选“新产品新技术TOP100”和高交会优秀产品，其中，“大脑360”产品入选了“2024年脑机接口十大创新案例”，面向脑智教育的“认知能力与心理健康脑电测训系统”通过了教育部学校规划建设发展中心“未来学校研究与实验计划”重大创新成果认证。</div>
      <div className='leading-6 mt-4'>经过在脑机接口领域的多年耕耘，公司产品已大规模应用于超5000家学校、高校、科研院所、医院、政府机关、戒毒、消防、监狱、公检法等企事业单位。依托在脑机接口领域丰富的产品线及应用系统，公司已构建了TB级的融合同步脑电和认知行为数据的大数据平台，建设了一个开放的、面向科研和应用的脑电数据研究平台，为脑认知、脑智发育、脑机智能等脑科学领域研究提供基于大数据和人工智能的产品和服务。</div>
    </div>
  </div>
}

export default About