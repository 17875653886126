import { useEffect, useState } from "react"
import { Empty, message, Pagination } from "antd"
import SysNewsItem from "../../components/SysNewsItem"
import { reqArticleList } from "../../util/api"
import { useSearchParams } from "react-router-dom"

/**
 * 新闻列表
 * @returns 
 */
const NewsList = () => {

  // 
  const [searchParams] = useSearchParams()
  // 
  const newsTypeVal = searchParams.get('type')
  // 新闻类型
  const [newsType, setNewsType] = useState(newsTypeVal ? newsTypeVal : 'news')
  // 新闻数据
  const [newsData, setNewsData] = useState([])
  // 新闻总数
  const [count, setCount] = useState(0)


  useEffect(() => {
    reqNewsList()
  }, [newsType])

  /**
   * 点击 新闻tab
   * @param {*} type 
   */
  const onClickNewsTab = (type) => {
    setNewsType(type)
  }

  /**
   * 获取新闻列表
   */
  const reqNewsList = (page) => {

    reqArticleList({ page: page ? page : 1, type: newsType === 'news' ? 1 : newsType === 'classroom' ? 2 : 3 }).then((res) => {
      if (res.res === 1) {
        res.list.forEach(element => {
          element.key = element.id
        })
        setCount(res.list.length)
        setNewsData(res.list)
      }
      else {
        message.warning(res.msg)
      }
    })
  }

  /**
   * 换页
   * @param {*} page 
   * @param {*} pageSize 
   */
  const onChangePage = (page, pageSize) => {
    reqNewsList(page)
  }

  return <div className="w-full h-full page_newslist">
    <div className='w-1200 m-auto mt-16 flex flex-row justify-between'>

      <div className="" style={{ width: '180px' }}>
        <div className="flex flex-row items-center text-sky-400">
          <div className="w-1 h-1 bg-sky-400 mr-4"></div>
          <div className={`w-20 h-8 leading-8 cursor-pointer rounded-md text-center ${newsType === 'news' ? 'bg-sky-400' : ''} ${newsType === 'news' ? 'text-white' : ''}`} onClick={() => { onClickNewsTab('news') }}>公司新闻</div>
        </div>
        <div className="flex flex-row items-center text-sky-400 mt-2">
          <div className="w-1 h-1 bg-sky-400 mr-4"></div>
          <div className={`w-20 h-8 leading-8 cursor-pointer rounded-md text-center ${newsType === 'classroom' ? 'bg-sky-400' : ''} ${newsType === 'classroom' ? 'text-white' : ''}`} onClick={() => { onClickNewsTab('classroom') }}>家长课堂</div>
        </div>
        <div className="flex flex-row items-center text-sky-400 mt-2">
          <div className="w-1 h-1 bg-sky-400 mr-4"></div>
          <div className={`w-20 h-8 leading-8 cursor-pointer rounded-md text-center ${newsType === 'brain' ? 'bg-sky-400' : ''} ${newsType === 'brain' ? 'text-white' : ''}`} onClick={() => { onClickNewsTab('brain') }}>科学用脑</div>
        </div>
      </div>

      <div className="" style={{ width: '1000px' }}>
        <div className="h-8 leading-8 mb-2">{newsType === 'news' ? '公司新闻' : newsType === 'classroom' ? '家长课堂' : '科学用脑'}</div>
        <div className="w-full flex flex-wrap bg-white p-8 pb-0 rounded-md">
          {
            newsData.map(item => <SysNewsItem key={item.id} newsData={item} />)
          }
          <div className="w-full flex flex-row justify-center">
            {
              count / 20 < 1 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='暂无数据' />
            }
          </div>
        </div>
        <div className="py-8">
          {count / 20 > 1 && <Pagination align="center" total={count} pageSize={20} onChange={onChangePage} />}
        </div>
      </div>

    </div>
  </div>
}

export default NewsList